* {
    box-sizing: border-box;
    cursor: none;
    scroll-behavior: smooth;

}

html,
body {
    height: 100%;
    #root {
        height: 100%;
    }

    .cursor {
        position: fixed;
        top: -12px;
        left: -12px;

        width: 24px;
        height: 24px;
        border-radius: 16px;
        background-color: #dee2f8;
        mix-blend-mode: exclusion;
        z-index: 999;
        pointer-events: none;
    }

    .nav-link {
        font-weight: 400;
        font-size: 18px;
        color: #dee2f8;

        a {
            display: block;
            position: relative;

            &:hover {
                color: #ffffff;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: -0.2rem;
                width: 100%;
                height: 0.1em;
                background-color: #ffffff;
                transform: scaleX(0);
                transform-origin: center;
                transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            &:hover::after {
                opacity: 1;
                transform: scaleX(1);
            }
        }
    }

    .scroll-navbar {
        transition: all 0.1s linear;
        backdrop-filter: blur(50px);
        z-index: 50;
        background: rgba(0, 0, 0, 0.07);
    }

    .active-collapse {
        transition: all 0.1s ease;
        backdrop-filter: blur(40px);
        height: 100%;
    }
}

.active {
    border-bottom: 3px solid white;
    color: white;
}

.inactive {
    text-decoration: none;
}

.section {
    background: black;
    border: none;
}

.nav {
    display: flex;
}
